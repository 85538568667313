<!--
 * @Descripttion: 
 * @Author: zhongbin.chen
 * @Date: 2021-11-04 10:15:58
-->
<template>
  <div id="homeBarChartsRight" :class="className" :style="{height: height, width: width}" />
</template>

<script lang="ts">
import {
  nextTick,
  defineComponent,
  onActivated,
  onBeforeUnmount,
  onDeactivated,
  onMounted,
  PropType,
  watchEffect,
  watch
} from "vue";
import resize from "@/components/charts/mixins/resize";
import { init, EChartsOption } from "echarts";
import moment from 'moment'
import i18n from '@/locales/index'
const _t = i18n.global.t

export interface pieChartData {
  roomList: number[];
  durationList: number[];
}

export default defineComponent({
  props: {
    className: {
      type: String,
      default: "chart"
    },
    width: {
      type: String,
      default: "98%",
      required: true
    },
    height: {
      type: String,
      default: "500px",
      required: true
    },
    chartData: {
      type: Object as PropType<pieChartData>,
      required: true
    }
  },
  setup(props) {
    const { mounted, chart, beforeDestroy, activated, deactivated } = resize();

    const getHM = (value?: any) => {
      if (value == 0) { return value}
      let _h = value/3600
      const tempTime = moment.duration(value, 's');
      const h = tempTime.hours() ? Math.floor(_h) + 'h' : ''
      const m = tempTime.minutes() ? tempTime.minutes() + 'min' : ''
      const t = tempTime.seconds() ? tempTime.seconds() + 's' : ''
      return h + m + t;
    }
    const setOptions = (chartData: pieChartData) => {
      if (chart.value) {
        chart.value.setOption({
        // tooltip: {
        //   trigger: 'axis',
        //   axisPointer: {
        //     type: 'shadow'
        //   }
        // },
        legend: {
          left: '8%',
          selectedMode: false,
          itemWidth: 18,
          itemHeight: 4,
          data: [{
            name: _t('dataReport.disinfectTime'),
            icon: 'rect'
          }]
        },
        grid: {
          left: '0',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          show: false,
          splitLine:{ show: false },
          boundaryGap: [0, 0.01]
        },
        yAxis: {
          type: 'category', 
          axisLine: {    
            show: false
          },
          axisTick: {    
            show: false
          },
          data: chartData.roomList
        },
        series: [
          {
            name: _t('dataReport.disinfectTime'),
            type: 'bar',
            barWidth: 26,
            itemStyle: {
              normal: {
                color: "#965ee3",
								label: {
									show: true, //开启显示
									position: 'insideLeft', //在上方显示
									textStyle: { //数值样式
										color: '#000',
										fontSize: 14
                  },
                  formatter: function (val?:any) {
                    return getHM(val.value)
                  }
								}
							}
            },
            // label: {
            //   show: true,
            //   formatter: '{c}'
            // },
            data: chartData.durationList
          },
        ]
        } as EChartsOption);
      }
    };
    const resetHeight = (params?:any) => {
      let data = params.roomList, height
      if(data && data.length > 9) {
        height = data.length*50 
      } else {
        height = 400
      }
      chart.value.resize({height: height})
    }
    watch(props, (newProps, oldProps) => {
      if(props.chartData) {
        setOptions(props.chartData);
        // resetHeight(props.chartData)
      }
    });

    const initChart = () => {
      const lineChart = init(
        document.getElementById("homeBarChartsRight") as HTMLDivElement,
        "macarons"
      );
       if(props.chartData) {
        setOptions(props.chartData);
      }
      chart.value = lineChart;
      // resetHeight(props.chartData)
    };

    onMounted(() => {
      mounted();
      nextTick(() => {
        initChart();
        if(props.chartData) {
          setOptions(props.chartData);
        }
      });
    });

    onBeforeUnmount(() => {
      beforeDestroy();
      if (!chart.value) {
        return;
      }
      chart.value.dispose();
      chart.value = null;
    });

    onActivated(() => {
      activated();
    });

    onDeactivated(() => {
      deactivated();
    });

    return {};
  }
});
</script>

<template>
  <div class="partTop">
    <el-row>
      <el-col :xs="24" :sm="8">
        <div class="item" style="margin-right: 10%">
          <p>{{$t('dataReport.robotNum')}}</p>	
          <p>
            <span class="card-panel-num">{{robotNum}}</span>
            <span>{{$t('dataReport.platform')}}</span>
          </p>	
        </div>
      </el-col>
      <el-col :xs="24" :sm="8">
        <div class="item" style="margin: 0 5%">
          <p>{{$t('dataReport.cumulativeTaskTimes')}}</p>	
          <p>
            <span class="card-panel-num">{{count}}</span>
            <span>{{$t('robot.second')}}</span>
          </p>	
        </div>
      </el-col>
      <el-col :xs="24" :sm="8">
        <div class="item" style="margin-left: 10%">
          <p>{{$t('dataReport.disinfectDuration')}}</p>	
          <p>
            <span class="card-panel-num" v-show="hours!=0">{{hours}}</span>
            <span style="padding-right: 10px" v-show="hours!=0">h</span>
            <span class="card-panel-num" v-show="minutes!=0">{{minutes}}</span>
            <span style="padding-right: 10px" v-show="minutes!=0">min</span>
            <span class="card-panel-num">{{seconds}}</span>
            <span>s</span>
          </p>	
        </div>
      </el-col>
    </el-row>
  </div>	
</template>

<script>
import {
  defineComponent,
  onMounted,
  reactive,
  watch,
  ref,
  nextTick,
  toRefs
} from "vue";
import { CountTo } from 'vue3-count-to'
import { disinfectCount, disinfectDuration } from '@/apis/robot'
import { getRobotList } from '@/apis/user'
import { useRouter, useRoute } from "vue-router";
import moment from "moment";
import { getHM, totalImg, setRobotName, disinfectType } from "@/utils/robot";
import { taskList } from '@/apis/robot'
import i18n from '@/locales/index'
let _t = i18n.global.t

export default {
  components: { CountTo },
  setup() {
		const router = useRouter()
		const route = useRoute()
    const state = reactive({
      time: 2000,
      robotNum: '',
      count: '',
      duration: '',
      hours: '',
      minutes: '',
      seconds: '',
			getData() {
				let params = {
          areaid: 0,
          roomid: 0,
          begin: -1,
          end: -1
        }
        let data = {
          page: 1,
          pageSize: 5,
          limit: true,
          search: ''
        }
        getRobotList(data).then((res => {
          if (res.code == 200) {
            state.robotNum = res.data.total
          }
        }))
				disinfectCount(params).then((res => {
          if (res.code == 200) {
            let data = JSON.parse(res.data)
            state.count = data.count
          }
				}))
				disinfectDuration(params).then((res => {
          if (res.code == 200) {
            let data = JSON.parse(res.data)
            let val = data.duration
            val = val * 1000
            const tempTime = moment.duration(val);
            state.hours = tempTime.asHours()>=0 ? parseInt(tempTime.asHours()) : ''
            state.minutes = tempTime.minutes()>=0 ? tempTime.minutes() : ''
	          state.seconds = tempTime.seconds()>=0 ? tempTime.seconds() : ''
          }
				}))
			},
    });
    onMounted(() => {
			state.getData()
    });
    return {
      ...toRefs(state)
    };
  }
};
</script>

<style lang="scss" scoped>
.dataReport {
	padding: 20px 20px 0;
  height: 120px;
	.partTop {
		.item {
			padding: 20px;
			background: #fff;
			p {
				margin: 0 0 20px;
			}
			.card-panel-num {
				font-size: 30px;
				padding-right: 10px;
			}
		}
	}
}
</style>

<!--
 * @Descripttion: 
 * @Author: zhongbin.chen
 * @Date: 2021-11-08 19:55:16
-->
<template>
  <div id="homeBarChartsLeft" :class="className" :style="{height: height, width: width}" />
</template>

<script lang="ts">
import {
  nextTick,
  defineComponent,
  onActivated,
  onBeforeUnmount,
  onDeactivated,
  onMounted,
  PropType,
  watchEffect,
  watch
} from "vue";
import resize from "@/components/charts/mixins/resize";
import { init, EChartsOption } from "echarts";
import i18n from "@/locales";
const _t = i18n.global.t

export interface pieChartData {
  roomList: number[];
  countList: number[];
}

export default defineComponent({
  props: {
    className: {
      type: String,
      default: "chart"
    },
    width: {
      type: String,
      default: "98%",
      required: true
    },
    height: {
      type: String,
      default: "500px",
      required: true
    },
    chartData: {
      type: Object as PropType<pieChartData>,
      required: true
    }
  },
  setup(props) {
    const { mounted, chart, beforeDestroy, activated, deactivated } = resize();

    const setOptions = (chartData: pieChartData) => {
      if (chart.value) {
        chart.value.setOption({
        // tooltip: {
        //   trigger: 'axis',
        //   axisPointer: {
        //     type: 'shadow'
        //   }
        // },
        legend: {
          left: 'right',
          selectedMode: false,
          itemWidth: 18,
          itemHeight: 4,
          data: [{
            name: _t('dataReport.taskCount'),
            icon: 'rect'
          }]
        },
        grid: {
          left: '3%',
          right: '-6%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          inverse: true,
          show: false,
          splitLine:{ show: false },
          boundaryGap: [0, 0.01]
        },
        yAxis: {
          type: 'category',
          position: 'right',
          show: false,
          data: chartData.roomList
        },
        series: [
          {
            name: _t('dataReport.taskCount'),
            type: 'bar',
            barWidth: 26,
            itemStyle: {
              normal: {
                color: "#1890FF",
								label: {
									show: true, //开启显示
									position: 'insideRight', //在上方显示
									textStyle: { //数值样式
										color: '#000',
										fontSize: 14
                  },
                  formatter: '{c} '+ _t('robot.second')
								}
							}
            },
            data: chartData.countList
          },
        ]
        } as EChartsOption);
      }
    };

    const resetHeight = (params?:any) => {
      let data = params.roomList, height
      if(data && data.length > 9) {
        height = data.length*50 
      } else {
        height = 400
      }
      chart.value.resize({height: height})
    }
    watch(props, (newProps, oldProps) => {
      if(props.chartData) {
        setOptions(props.chartData);
      }
    });
    const initChart = () => {
      const lineChart = init(
        document.getElementById("homeBarChartsLeft") as HTMLDivElement,
        "macarons"
      );
      if(props.chartData) {
        setOptions(props.chartData);
      }
      chart.value = lineChart;
      // resetHeight(props.chartData)
    };

    onMounted(() => {
      mounted();
      nextTick(() => {
        initChart();
        if(props.chartData) {
          setOptions(props.chartData);
        }
      });
    });

    onBeforeUnmount(() => {
      beforeDestroy();
      if (!chart.value) {
        return;
      }
      chart.value.dispose();
      chart.value = null;
    });

    onActivated(() => {
      activated();
    });

    onDeactivated(() => {
      deactivated();
    });

    return {};
  }
});
</script>


import {
  nextTick,
  defineComponent,
  onActivated,
  onBeforeUnmount,
  onDeactivated,
  onMounted,
  PropType,
  watch,
  computed
} from "vue";
import { useStore } from '@/store'
import resize from "@/components/charts/mixins/resize";
import { init, EChartsOption } from "echarts";
import i18n from "@/locales";
const _t = i18n.global.t

export interface pieChartData {
  robotNameList: number[];
  pieList: number[];
  totalCount: string
}

export default defineComponent({
  props: {
    className: {
      type: String,
      default: "chart"
    },
    width: {
      type: String,
      default: "100%",
      required: true
    },
    height: {
      type: String,
      default: "420px",
      required: true
    },
    chartData: {
      type: Object as PropType<pieChartData>,
      required: true
    }
  },
  setup(props) {
    const { mounted, chart, beforeDestroy, activated, deactivated } = resize();

    const store = useStore()
    const language = computed(() => {
      return store.state.app.language
    })
    const titleStyle=computed(():string=>{
      switch(store.state.app.language){
        case 'en':
          return 'margin-left:-74px';
        case 'es':
          return 'margin-left:-82px; margin-top:10px';
        default:
          return 'margin-left:-44px;'
      }
    });
    const setOptions = (chartData: pieChartData) => {
      if (chart.value) {
        chart.value.setOption({
         tooltip: {
          trigger: 'item',
          // formatter: '{b} <br/> '+ _t('dataReport.rate')+': {d}%',
          formatter: (params) => {
            return params.data.robotName+' <br/> '+ _t('dataReport.rate')+': '+ params.data.percent +'%'
          }
        },
        legend: {
          left: '56%',
          top: '20%',
          itemWidth: 8,
          itemHeight: 8,
          borderRadius: 8,
          height: 100,
          selectedMode: false,
          formatter: function (val) {
            let obj = { value: '', percent: '', status: '', robotName: ''}, data = chartData.pieList
            if(chartData.pieList && chartData.pieList.length > 0) {
              chartData.pieList.map((item?: any) => {
                if(item.name == val) {
                  obj = item
                }
              })
            }
            let text =  '{a|' + obj.robotName
            obj?.status === 'leave' ? text = text + '(' + _t('disinfect.yijlk') + ')' : null //增加显示是否已离开
            text = text + ' | ' + obj.percent + '%' + '  ' + obj.value+ 'times' + '}'
            return text;
          },
          textStyle: {
            backgroundColor: "transparent", 
            rich: {
              a: {
                width: 220,
                height: 20
              },
            },
          },
        },
        // title: {
        //   // text: [
        //   //         '任务次数总计',
        //   //         '  ' + chartData.totalCount,
        //   //       ].join('\n'),
        //   text: _t('dataReport.taskCountTotal'),
        //   subtext: ' ' + chartData.totalCount,
        //   left: "32.5%",
        //   top: "40%",
        //   textStyle: {
        //     textAlign: "center",
        //     fill: "#8c8c8c",
        //     fontSize: 16,
        //     fontWeight: 400,
        //   },
        //   subtextStyle: {
        //     textAlign: "center",
        //     fill: '#000',
        //     fontSize: 40,
        //     fontFamily: 'PingFangSC-Regular'
        //   }
        // },
        // graphic: {
        //   type: "text",
        //   left: "22%",
        //   top: "42%",
        //   style: {
        //     text: "任务次数总计",
        //     textAlign: "center",
        //     fill: "#333",
        //     fontSize: 16,
        //   }
        // },
        color:['#5b8ff9', '#61ddaa', '#65789b', '#f6bd16', '#7262fd' , '#78d3', '#9661bc', '#f6903d' , '#008685', '#f08bb4'],
        series: [
          {
            name: 'WEEKLY WRITE ARTICLES',
            type: 'pie',
            itemStyle: {
              normal: {
                borderColor: '#fff',
                borderWidth: 10,
                label: {
                  show: false,
                },
                labelLine: {
                  show: false,
                },
              }
            },
            // roseType: 'radius',
            radius: ['40%', '70%'],
            center: ['32%', '50%'],
            data:  chartData.pieList,
            animationEasing: 'cubicInOut',
            animationDuration: 260
          }
        ]
        } as EChartsOption);
      }
    };

    const initChart = () => {
      const lineChart = init(
        document.getElementById("homePieCharts") as HTMLDivElement,
        "macarons"
      );
      if(props.chartData) {
        setOptions(props.chartData);
      }
      chart.value = lineChart;
    };

    watch(props, (newProps, oldProps) => {
      if(props.chartData) {
        setOptions(props.chartData);
      }
    });
    onMounted(() => {
      mounted();
      nextTick(() => {
        initChart();
        if(props.chartData) {
          setOptions(props.chartData);
        }
      });
    });

    onBeforeUnmount(() => {
      beforeDestroy();
      // if (!chart.value) {
      //   return;
      // }
      // chart.value.dispose();
      // chart.value = null;
    });

    onActivated(() => {
      activated();
    });

    onDeactivated(() => {
      deactivated();
    });

    return {
      language,
      titleStyle
    };
  }
});

<!--
 * @Descripttion: 
 * @Author: zhongbin.chen
 * @Date: 2021-11-03 14:15:58
-->
<template>
  <div class="lineBox">
    <div :style="{height: height, width: width}" ref="chartContainer" />
  </div>
</template>

<script lang="ts">
import {
  nextTick,
  defineComponent,
  onActivated,
  onBeforeUnmount,
  onDeactivated,
  onMounted,
  PropType,
  watchEffect,
  watch,
  ref,
  reactive,
} from "vue";
import resize from "@/components/charts/mixins/resize";
import { getHMS } from '@/utils/robot' 
import * as echarts from 'echarts';
import i18n from "@/locales";
const _t = i18n.global.t

export interface LineChartData {
  timeList: number[];
  countList: number[];
  durationList: number[];
}

export default defineComponent({
  props: {
    className: {
      type: String,
      default: "chart"
    },
    width: {
      type: String,
      default: "100%",
      required: true
    },
    height: {
      type: String,
      default: "400px",
      required: true
    },
    chartData: {
      type: Object as PropType<LineChartData>,
      required: true
    }
  },
  setup(props) {
    const chartContainer = ref<HTMLElement>()
    // @ts-ignore
    const charts = reactive({ chart: null } as ChartsType)  // 设置为 reactive 响应式对象，保存echarts 实例

    const { mounted, chart, beforeDestroy, activated, deactivated } = resize();
    const getTimeChange = (time: any) => {
      if (isNaN(time)) return null 
      if (typeof time === 'number') return time 
      const str = getHMS(time)
      return str
    }
    const setOptions = (chartData: LineChartData) => {
      const options = {
        tooltip: {
          trigger: 'axis',
          valueFormatter: (value?:any) => getTimeChange(value),
          axisPointer: {
            // type: 'cross',
          }
        },
        legend: {
          data: [_t('dataReport.taskCount'), _t('dataReport.taskTime')],
          icon: 'roundRect',
          itemHeight: 4 ,
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: chartData.timeList,
          }
        ],
        yAxis: [
          {
            name: "",
            type: 'value',
            splitLine: { 
              lineStyle: {
                type: 'dashed'
              } 
            },
          },
          {
            name: "",
            type: 'value',
            axisLabel: {
              formatter: function(value?:any, index?:any){
                return (value/3600).toFixed(2) + 'h'   
              }
            },
          }
        ],
        series: [
          {
            name: _t('dataReport.taskCount'),
            type: 'line',
            itemStyle: {
              normal: {
                color: '#1890FF'
              }
            },
            lineStyle: {
              width: 4
            },
            smooth: true,
            showSymbol: false,
            data: chartData.countList,
            animationDuration: 1000,
            animationEasing: "cubicInOut"
          },
          {
            name: _t('dataReport.taskTime'),
            type: 'line',
            lineStyle: {
              width: 4
            },
            itemStyle: {
              normal: {
                color: '#2FC25B'
              }
            },
            smooth: true,
            showSymbol: false,
            yAxisIndex: 1,
            data: chartData.durationList,
            animationDuration: 1000,
            animationEasing: "quadraticOut",
          }
        ],
        grid: {
          left: 14,
          right: 14, 
          bottom: 20,
          top: 60,
          containLabel: true
        },
      }
      const chart = echarts.init(chartContainer.value as HTMLElement)  //   这样赋值，不直接将 echarts 实例 赋值给 reactive 响应式对象
      chart.setOption(options, true)
      charts.chart = chart
      // @ts-ignore
      window.onresize = chart.resize;
    };
    const initChart = () => {
      if(props.chartData) {
        setOptions(props.chartData)
      }
    };
    onMounted(() => {
      mounted();
      nextTick(() => {
        initChart();
      });
    });
    watch(props, (newProps, oldProps) => {
      initChart();
      // console.log('lineChart=>', props.chartData)
    });

    onBeforeUnmount(() => {
      beforeDestroy();
      // if (!chart.value) {
      //   return;
      // }
      // chart.value.dispose();
      // chart.value = null;
    });

    onActivated(() => {
      activated();
    });

    onDeactivated(() => {
      deactivated();
    });

    return {
      chartContainer,
      charts
    };
  }
});
</script>
<style lang="scss" scoped>
.lineBox {
  p {
    text-align: center;
    font-size: 12px;
    .rect {
      display: inline-block;
			width: 18px;
			height: 2px;
      margin: 4px 10px;
    }
  }
}
</style>
<!--
 * @Descripttion: 
 * @Author: zhongbin.chen
 * @Date: 2021-09-07 16:16:40
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-03-30 09:42:34
-->
<template>
	<div>
		<el-breadcrumb separator="/" class="breadcrumb">
			<el-breadcrumb-item>{{ $t("route.dashboard") }}</el-breadcrumb-item>
		</el-breadcrumb>
		<div class="dataReport">
			<PanelGroup />
			<Search :title="$t('dataReport.title1')" type="1" @getChartData_l="getChartData_l"/>
			<div class="chartBox">
				<LineChart :chart-data="lineChartData" v-if="isShow_l"/>
				<p class="noChart" v-else >{{$t('system.noData')}}</p>	
			</div>	
			
			<Search :title="$t('dataReport.title2')" type="2" @getChartData_b="getChartData_b"/>
			<div class="chartBox">
				<el-row  v-if="isShow_b"> 
					<!-- <el-col :xs="24" :sm="11">
						<BarChartLeft  :chart-data="barChartData"/>
					</el-col>
					<el-col :xs="24" :sm="13">
						<BarChartRight :chart-data="barChartData"/>
					</el-col> -->
					<el-col :xs="24">
						<BarChart :chart-data="barChartData"/>
					</el-col>
				</el-row>
				<p class="noChart" v-else >{{$t('system.noData')}}</p>	
			</div>	
			<Search :title="$t('dataReport.title3')" type="3" @getChartData_p="getChartData_p"/>
			<div class="chartBox">
				<PieChart :chart-data="pieChartData" v-if="isShow_p"/>
				<p class="noChart" v-else >{{$t('system.noData')}}</p>	
			</div>	
		</div>
	</div>	
</template>

<script>
import {
  defineComponent,
  onMounted,
  reactive,
  watch,
  ref,
  nextTick,
  toRefs
} from "vue";
import { CountTo } from 'vue3-count-to'
import PanelGroup from './components/PanelGroup.vue'
import LineChart from './components/LineChart.vue'
import PieChart from './components/PieChart.vue'
import BarChartLeft from './components/BarChartLeft.vue'
import BarChartRight from './components/BarChartRight.vue'
import BarChart from './components/BarChart.vue'
import Search from './components/Search.vue'
import i18n from '@/locales/index'
let _t = i18n.global.t

export default {
  components: { CountTo, PanelGroup, LineChart, PieChart, BarChartLeft, BarChartRight, Search, BarChart },
  setup() {
    const state = reactive({
			isShow_l: true,
			isShow_b: true,
			isShow_p: true,
			lineChartData: '',
			barChartData: '',
			pieChartData: '36',
			getChartData_l(val) {
				state.lineChartData = val.params
				state.isShow_l = val.isShow
			},
			getChartData_b(val) {
				state.barChartData = val.params
				state.isShow_b = val.isShow
			},
			getChartData_p(val) {
				state.pieChartData = val.params
				state.isShow_p = val.isShow
			},
    });
    onMounted(() => {
			// state.getAreaList()
			// state.getAreaTaskDisinfect()
    });
    return {
      ...toRefs(state)
    };
  }
};
</script>

<style lang="scss" scoped>
.dataReport {
	padding: 24px;
	.partTop {
		.item {
			padding: 20px;
			background: #fff;
			p {
				margin: 0 0 20px;
			}
			.card-panel-num {
				font-size: 30px;
				padding-right: 10px;
			}
		}
	}
	.noChart {
		margin-top: 130px;
		color: #999;
		text-align: center;
	}
	.chartBox {
		background: #fff;
		min-height: 400px;
		padding: 30px 20px 0;
	}
}
</style>

<!--
 * @Descripttion: 
 * @Author: zhongbin.chen
 * @Date: 2021-12-28 10:40:06
-->
<template>
  <div class="barBox">
		<div class="barLeft">
			<h>
				<span style="background: #1890FF"></span>
				<span>{{$t('dataReport.taskCount')}}</span>
			</h>	
			<p v-for="(item, index) in chartData.countList" :key="index">
				<!-- <span class="barText">{{item}}{{item == 0 ? '' : $t('robot.second')}}</span>	 -->
				<span class="barItem" :style="{width: getWidth(1, item)}">
					<span class="barText" :class="{'newbartext':getLanguage()=='en'||getLanguage()=='es'||item>99}">{{item}}{{item == 0 ? '' : $t('robot.second')}}</span>	
				</span>	
			</p>	
		</div>
		<div class="barCenter">
			<p class="centerText" v-for="(item, index) in chartData.roomList" :key="index" :title="item">{{item}}</p>	
		</div>
		<div class="barRight">
			<h>
				<span style="background: #965EE3"></span>
				<span>{{$t('dataReport.disinfectTime')}}</span>
			</h>	
			<p v-for="(item, index) in chartData.durationList" :key="index" >
				<span class="barItem" :style="{width: getWidth(2, item)}"></span>
				<!-- <span>{{getHM(item)}}</span>	 -->
				<span class="bartext">{{getHM(item)}}</span>	
			</p>	
		</div>
  </div>
</template>

<script>
import {
  reactive,
  ref,
	toRefs,
	onMounted,
  watch
} from "vue";
import moment from 'moment'
import { getLanguage} from '@/utils/cookies'
import i18n from '@/locales/index'
const _t = i18n.global.t
export default {
	props: [ "chartData" ],
  setup(props, context) {
		console.log('props.chartData=>', props.chartData)
    const state = reactive({
			chartData: props.chartData,
			options: [],
			max_c: props.chartData.max_c,
			max_d: props.chartData.max_d,
			getHM(value)  {
				if (value == 0) { return value}
				let _h = value/3600
				const tempTime = moment.duration(value, 's');
				const h = tempTime.hours() ? Math.floor(_h) + 'h' : ''
				const m = tempTime.minutes() ? tempTime.minutes() + 'min' : ''
				const t = tempTime.seconds() ? tempTime.seconds() + 's' : ''
				return h + m + t;
			},
			getWidth(type, val) {
				if (val == 0) {
					return val
				}
				return type == 1 ? val*100/state.max_c + '%' : val*100/state.max_d + '%'
			},
    });
    watch(props, (newProps, oldProps) => {
      if(props.chartData) {
				state.max_c = props.chartData.max_c
				state.max_d = props.chartData.max_d
        console.log('props.chartData=>', props.chartData)
      }
    });
    onMounted(() => {
    });
    return {
      ...toRefs(state),
	  getLanguage
    };
  }
};
</script>

<style lang="scss" scoped>
.barBox {
	white-space: nowrap;
	text-align: center;
	div	{
		display: inline-block;
		vertical-align: top;
	}
	h {
		height: 70px;
		line-height: 70px;
		font-size: 12px;
		span:nth-child(1) {
			display: inline-block;
			width: 18px;
			height: 4px;
			margin: 2px 10px;
		}
	}
	p {
		height: 26px;
		line-height: 26px;
		margin: 0 0 20px 0;
		font-size: 12px;
	}
	.barCenter {
		padding: 70px 28px 0;
		.centerText{
			max-width: 46px;
    	white-space: nowrap;
    	overflow: hidden;
    	text-overflow: ellipsis;
		}
	}
	.barLeft, .barRight {
		width: 38%;
	}
	.barLeft {
		text-align: right;
		p {
			text-align: right;
			.barItem {
				position: relative;
				display: block;
				height: 26px;
				float: right;
				background: #1890FF;
			}
			.barText {
				position: absolute; 
    		    left: -27px;
			}
			.newbartext{
				left: -47px;
			}
		}
	}
	.barRight {
		text-align: left;
		.barItem{
			background: #965EE3;
			height: 26px;
			display: inline-block;
		}
		.bartext {
			padding-left: 10px;
			vertical-align: top;
		}
	}
}
</style>
